<template>
    <Transition>
      <div v-if="show && !hidden" class="fixed right-5 bottom-5 z-[2] text-neutral-500">
        <AtomsImagesIcon :icon="`back-to-top${locale.locale === 'cs' ? '' : `-${locale.locale}`}`" class="text-[7rem] bg-secondary-200 p-1 cursor-pointer transition-all duration-300 hover:text-primary-500" @click="scrollToTop" />
      </div>
    </Transition>
</template>
<script setup>

const locale = useLocale().getLocale();

let show = ref(false);

const hidden = useState('backToTopHidden', () => false);

const checkScroll = () => {
  show.value = window.scrollY > 100;
};

onMounted(() => {
  window.addEventListener('scroll', checkScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', checkScroll);
});

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });

  useBaseAnalytics().pushEvent('click', {
    click_text: 'zpět nahoru',
    click_destination: 'internal'
  });
};

</script>